import React, { useState } from "react"

import styled from "@emotion/styled"

import LogoBlue from "../assets/svg/hella-blue.svg"

const ContactBtnContainer = styled.div`
  z-index: 99;
  position: fixed;
  margin: 0;
  top: 0;
  right: 0;
  width: 390px;
  transition: all 1s ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5em;
  color: #ffaa5c;
  h2 {
    transition: all 0.5s ease;
    margin: 0;
    text-align: left;
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 1.75em;
    font-weight: 800;
    padding-right: 20px;
  }
  &:hover,
  &:active {
    h2 {
      letter-spacing: 0.1em;
      color: #f27329;
    }
  }
  @media only screen and (max-width: 576px) {
    width: 260px;
  }
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #f27329;
`

const Wrapper = styled.div`
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  background: linear-gradient(
    66deg,
    rgba(0, 0, 0, 0.95) 0%,
    rgba(0, 0, 0, 0.99) 100%
  );
  transition: opacity 0.75s ease;
`

const LogoContainer = styled.div`
  z-index: 4;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  svg {
    position: fixed;
    bottom: 0px;
    left: 10px;
    width: 75vw;
    padding: 10px;
  }
`

const Container = styled.div`
  z-index: 4;
  max-width: 720px;
  margin: 20px;
  padding: 40px;
  text-align: center;
  color: #0420bf;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 920px) {
    max-width: 100%;
  }
`

const Form = styled.form`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  margin: auto;
  input,
  textarea,
  button {
    ::placeholder {
      color: #fff;
    }
    color: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #fff;
    background: none;
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 1em;
    font-weight: 500;
    transition: opacity 0.5s ease;
  }
  input,
  textarea {
    &:focus {
      color: #fff;
      border: 1px solid #0420bf;
    }
  }
  button {
    cursor: pointer;
    background: #0420bf;
    color: #fff;
    &:hover,
    &:active {
      background: #f27329;
      border: 1px solid #fff;
      color: #fff;
    }
  }
`

const Address = styled.div`
  margin-bottom: 40px;
  text-align: center;
  font-family: "GT-Super-Display";
  color: #fff;
  font-weight: 900;
  font-size: 4em;
  @media only screen and (max-width: 920px) {
    font-size: 2em;
  }
  @media only screen and (max-width: 576px) {
    font-size: 1.5em;
  }
`

const Copyright = styled.span`
  font-family: "Syne", Helvetica, sans-serif;
  color: #fff;
  font-weight: 600;
  padding-top: 0.5em;
  font-size: 0.75em;
`

const Contact = () => {
  const [contactOpen, setContactOpen] = useState(false)

  const handleToggle = () => {
    setContactOpen(!contactOpen)
  }

  return (
    <>
      <ContactBtnContainer onClick={handleToggle}>
        <h2>Contact</h2>
        <Line></Line>
      </ContactBtnContainer>
      <Wrapper
        css={{
          opacity: contactOpen ? 1 : 0,
          pointerEvents: contactOpen ? "initial" : "none",
        }}
      >
        <LogoContainer onClick={handleToggle}>
          <LogoBlue />
        </LogoContainer>
        <Container>
          <Address>
            31 Howard St., 4th Floor
            <br />
            New York, NY 10013
          </Address>
          <Form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <input type="text" name="name" placeholder="Name" />
            <input type="email" name="email" placeholder="Email" />
            <textarea type="text" name="message" placeholder="Message" />
            <button type="submit">Submit</button>
          </Form>
          <Copyright> © {new Date().getFullYear()}, Hella Video LLC</Copyright>
        </Container>
      </Wrapper>
    </>
  )
}

export default Contact
