import React from "react"

import { css, Global } from "@emotion/react"
import PropTypes from "prop-types"
import { useScrollPercentage } from "react-scroll-percentage"

import Hero from "./sections/Hero"
import Footer from "./sections/Footer"

const Layout = ({ children }) => {
  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  })

  const footerPos = percentage.toPrecision(2)

  return (
    <>
      <Global
        styles={css`
          html,
          body {
            margin: 0;
            background: rgb(4, 32, 191);
          }
          main {
            z-index: 3;
            position: relative;
            top: 100vh;
          }
          h2 {
            text-align: center;
            font-family: "GT-Super-Display";
            font-weight: 700;
            font-size: 60px;
          }
          p {
            text-align: left;
            font-family: "GT-Super-Text";
            font-weight: 300;
            font-size: 1.5em;
            line-height: 2em;
          }
          @media only screen and (max-width: 991px) {
            p {
              font-size: 1.25em;
            }
          }
        `}
      />
      <>
        <Hero footerPos={footerPos} />
        <main>{children}</main>
        <div ref={ref}>
          <Footer />
        </div>
      </>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
