import React from "react"

import styled from "@emotion/styled"
import { useScrollPercentage } from "react-scroll-percentage"

import Contact from "../contact"

import Logo from "../../assets/svg/hella-white.svg"
import heroVideo from "../../assets/video/heroloop.mp4"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(
    66deg,
    rgba(3, 37, 140, 0.95) 0%,
    rgba(4, 32, 191, 1) 100%
  );
  background: -webkit-linear-gradient(
    66deg,
    rgba(3, 37, 140, 0.95) 0%,
    rgba(4, 32, 191, 1) 100%
  );
  background: linear-gradient(
    66deg,
    rgba(3, 37, 140, 0.95) 0%,
    rgba(4, 32, 191, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#03258c",endColorstr="#0420bf",GradientType=1);
  @media only screen and (max-width: 1024px) {
  }
`

const VideoContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  video {
    width: 75%;
    height: auto;
    overflow: hidden;
    border: 1px solid white;
  }
`

const LogoContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  svg {
    position: fixed;
    bottom: 0px;
    left: 10px;
    width: 75vw;
    padding: 10px;
  }
`

const Marker = styled.div`
  position: absolute;
  top: 100vh;
`

const Hero = props => {
  const [ref, percentage] = useScrollPercentage({
    threshold: 0,
  })

  let scaleCalc = 0
  let opacityCalc = 0
  let footerPos = Number(props.footerPos)

  if (footerPos > 0) {
    scaleCalc = 2 - footerPos * 2
    opacityCalc = 0.5 + footerPos
  } else {
    scaleCalc = 1 + percentage.toPrecision(2) * 1
    opacityCalc = 1 - percentage.toPrecision(2) * 0.5
  }

  const myScale = {
    transform: "scale(" + scaleCalc + ")",
  }

  return (
    <>
      <Contact />
      <Wrapper>
        <VideoContainer>
          <video
            autoPlay
            loop
            muted
            playsInline
            css={{ opacity: opacityCalc }}
            style={myScale}
          >
            <source src={heroVideo} type="video/mp4" />
          </video>
        </VideoContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Wrapper>
      <Marker ref={ref}></Marker>
    </>
  )
}

export default Hero
