import * as React from "react"

import styled from "@emotion/styled"

const Wrapper = styled.div`
  margin-top: 100vh;
  pointer-events: none;
  position: relative;
  height: 100vh;
`

const Footer = () => <Wrapper></Wrapper>

export default Footer
